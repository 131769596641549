import React from "react"
import {
  BlogContainer,
  Row,
  SubTitleSection,
  Text,
} from "../components/atomics"
import { Link } from "gatsby"
import Page from "../components/Pages/Page"
import colors from "../colors"
import { URL_EMAIL, URL_INSTA_FLYNOW, URL_WHATSAPP } from "../constants"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import { Products } from "../utils/product"
import { useParams } from "../hooks"

const Combos = () => {
  const {query} = useParams()
  const images = {
    apps: Products.apps.map(i=> i.img),
    ebooks: Products.ebooks.map(i=> i.img),
    cursos: Products.cursos.map(i=> i.img),
  }
  const combo = [
    {
      title: "➡️ [50%OFF] 🔥 COMBO: 3 APPS + 3 EBOOKS + 2 CURSOS",
      description: (
        <>
          <b>Aplicativos</b>: Flynow Produtividade + Flynow Finanças Pessoais +
          Flynow Frases de Motivação.
          <br />
          <b>Ebooks</b>: Guia Definitivo para a Produtividade + Guia completo
          sobre Hábitos + Metas & Planejamento. <br />
          <b>Cursos</b>: Domine seus hábitos + Domine a arte da produtividade
        </>
      ),
      url: "/combo-apps-ebooks-cursos",
      imgs: Object.values(images).flat(),
    },
    {
      title: "➡️ [30%OFF] 🔥 COMBO: PREMIUM VITALÍCIO + 2 CURSOS",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> ao aplicativo Flynow Produtividade. <br/>
          <b>Cursos</b>: Domine seus hábitos + Domine a arte da produtividade
        </>
      ),
      url: "/combo-app-cursos",
      imgs: [images.apps[0], ...images.cursos],
    },
    {
      title: "➡️ [25%OFF] 🔥 COMBO: 3 APPS",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> aos aplicativos: Flynow Produtividade,
          Flynow Finanças Pessoais e Flynow Frases de Motivação.
        </>
      ),
      url: "/combo-apps",
      imgs: Object.values(images.apps),
    },
    {
      title: "➡️ [25%OFF] 🔥 COMBO: PREMIUM VITALÍCIO + CURSO PRODUTIVIDADE",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> ao aplicativo Flynow Produtividade. <br/>
          <b>Curso</b>: Domine a arte da produtividade
        </>
      ),
      url: "/combo-app-curso-produtividade",
      imgs: [images.apps[0], images.cursos[0]],
    },
    {
      title: "➡️ [25%OFF] 🔥 COMBO: PREMIUM VITALÍCIO + CURSO HÁBITOS",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> ao aplicativo Flynow Produtividade. <br/>
          <b>Curso</b>: Domine seus hábitos: A chave para a uma Vida
          Extraordinária.
        </>
      ),
      url: "/combo-app-curso-habito",
      imgs: [images.apps[0], images.cursos[1]],
    },
    {
      title: "➡️ [25%OFF] 🔥 COMBO: PREMIUM VITALÍCIO + 3 EBOOKS",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> ao aplicativo Flynow Produtividade. <br/>
          <b>Ebooks</b>: Guia Definitivo para a Produtividade + Guia completo
          sobre Hábitos + Metas & Planejamento. <br />
        </>
      ),
      url: "/combo-ebooks-app",
      imgs: [images.apps[0], ...images.ebooks],
    },
    {
      title: "➡️ [20%OFF] 🔥 COMBO: PREMIUM VITALÍCIO + EBOOK PRODUTIVIDADE",
      description: (
        <>
          <b>Acesso Premium Vitalício</b> ao aplicativo Flynow Produtividade. <br/>
          <b>Ebook</b>: Guia Definitivo para a Produtividade.
        </>
      ),
      url: "/combo-app-ebook",
      imgs: [ images.apps[0],images.ebooks[0]],
    },
  ]

  const description =
    "Combos promocionais do Flynow incluem assinaturas, ebooks e cursos. Economize até 50% na compra de nossos produtos."
  const language = "pt"
  return (
    <Page title={"Combos"} description={description} pageId="combos">
      <BlogContainer>
        <Text style={{ fontWeight: "500", lineHeight: "1.2rem" }}>
          🔥 Confira nossos combos promocionais do Flynow. Economize até 50% na compra de nossos produtos.
        </Text>
        <br />

        <SubTitleSection>Nossos Combos:</SubTitleSection>
        <div className="border" />
        {combo.map(item => (
          <div style={{ marginTop: "1.5rem", backgroundColor:'#f7f7f7', padding: 20, borderRadius: 12, }}>
            <Link
              to={item.url + query}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h3 style={{ color: colors.primary2 }}>{item.title}</h3>
              {item.imgs.map(i => (
                <img
                  src={i}
                  height={70}
                  style={{ marginRight: 10, marginTop: 20 }}
                />
              ))}
              <span style={{ display: "block", marginBottom: 10, marginTop: 5 }}>
                {item.description}
              </span>
              <button
                style={{
                  cursor: "pointer",
                  color: colors.white,
                  backgroundColor: colors.primary2,
                  border: "none",
                  padding: "10px 30px",
                  borderRadius: 8,
                  fontSize: "0.8rem",
                  alignSelf:'center'
                }}
              >
                Ver mais
              </button>
            </Link>
          </div>
        ))}

        <br />
        <br />
        <h3 style={{ textAlign: "center" }}>Alguma dúvida?</h3>
        <br />
        <span style={{ textAlign: "center" }}>
          Se tiver qualquer dúvida entre em contato conosco!
        </span>
        {[
          {
            link: URL_WHATSAPP,
            img: <FaWhatsapp />,
            description: "31 999107753",
          },
          {
            link: URL_EMAIL,
            img: <MdMailOutline />,
            description: "support@appflynow.com",
          },
          {
            link: URL_INSTA_FLYNOW,
            img: <FaInstagram />,
            description: "@appflynow",
          },
        ].map(i => (
          <a
            href={i.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              alignSelf: "center",
              textDecoration: "none",
              color: "inherit",
              fontSize: "2rem",
              color: colors.primary2,
            }}
          >
            <Row style={{ marginTop: 10, alignItems: "center" }}>
              {i.img}
              <h5>{i.description}</h5>
            </Row>
          </a>
        ))}
      </BlogContainer>
      <br />
      <br />
    </Page>
  )
}

export default Combos
